import type { GetStaticProps, NextPage } from 'next';

import { MainLayout } from '@components/Layout';
import Components from '@components/pages/blog';
import client, {
  coalesce,
  defaultLocale,
  getSanityShared,
  locale,
  noDraft,
  type SanityShared,
} from '@lib/integrations/sanity';
import type { BlogDocument, PostDocument } from '@raise/sanity';

type Props = {
  shared: SanityShared;
  page: BlogDocument;
  posts: PostDocument[];
};

const Posts: NextPage<Props> = ({ shared, page, posts }) => {
  if (posts.length === 0) return null;

  return (
    <MainLayout shared={shared} meta={page.meta}>
      <Components
        initialPosts={posts}
        initialPostsMetadata={{
          lastId: posts[posts.length - 1]._id || null,
          lastPosted: posts[posts.length - 1].posted || null,
        }}
        {...page}
      />
    </MainLayout>
  );
};

// @ts-ignore Sanity-typed can't generate types like this
export const getStaticProps: GetStaticProps<Props> = async (props) => ({
  props: {
    shared: await getSanityShared(props),
    // @ts-ignore Sanity-typed can't generate types like this
    page: await client.fetch(coalesce('blog'), props),
    // @ts-ignore Sanity-typed can't generate types like this
    posts: await client.fetch(
      `coalesce(
        *[_type == "post" && ${locale} && ${noDraft}],
        *[_type == "post" && ${defaultLocale} && ${noDraft}]
      ) | order(posted desc) [0...12]`,
      props,
    ),
  },
});

export default Posts;
